@mixin hovereffect {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.8;
  }
}

// todo: this should be a lib
