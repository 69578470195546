$colors: (
  grey: (
    100: #1d1d1d,
    200: #505050,
    300: #a4a4a4,
    500: #c9c9c9,
    700: #e8e8e8,
    900: #f5f5f5,
  ),
  green: (
    500: #0d682f,
    600: #00775a,
    700: #81ae92,
  ),
  red: (
    500: #8d161d,
    600: #c01e29,
  ),
  blue: (
    500: #2980b9,
  ),
  sand: (
    300: #b1913e,
    400: #b89840,
    500: #c09e43,
  ),
  text: (
    500: color(grey, 100),
  ),
  link: (
    500: color(blue),
  ),
  hover: (
    500: color(blue),
  ),
);
