html {
  display: flex;
  flex-direction: column;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  font-family: $fontOxygen;
  font-size: 18px;
  line-height: 1.5;
  font-weight: normal;
  color: color(text, 500);
  background-color: color(grey, 900);

  &--fixedfooter {
    .page-content {
      flex-grow: 1;
    }
  }

  @include media("tablet") {
    &--fixedheader {
      padding-top: $headerHeightMobile;
    }
  }
}
