@import "../config/sizes";
@import "../mixins/media";

.footer {
  padding: 96px 0 48px 0;
  background-color: color(text);
  color: white;
  font-size: 16px;
  line-height: 24px;
  a {
    color: white;
    text-decoration: underline;
    &:hover {
      color: color(grey, 700);
    }
  }
  &-logo {
    margin-bottom: 10px;
  }
  &-title {
    display: block;
    margin-bottom: 10px;
    @extend .title;
    @extend .title--contentitle;
  }
  &-info {
    line-height: 30px;
    p:last-child {
      margin-bottom: 0;
    }
  }
  &-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      position: relative;
      padding: 0 0 12px 0;
      > a {
        display: inline-flex;
        justify-content: flex-start;
        text-decoration: none;
        span {
          flex: 0 1 auto;
          overflow: auto;
        }
        i {
          margin-left: 15px;
          font-size: 24px;
        }
      }
    }
  }
  &-links {
    margin-top: 96px;
    display: flex;
    gap: 10px 32px;
    flex-wrap: wrap;
  }

  @include media("laptop") {
    padding: 40px 0 40px 0;
    &-links {
      margin-top: 80px;
    }
  }
  @include media("tablet") {
    &-links {
      margin-top: 40px;
    }
  }
  @include media("mobile") {
    &-links {
      margin-top: 20px;
    }
  }
}
