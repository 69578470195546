.colorbox {
  padding: 40px 64px;
  color: white;
  background-color: color(green, 600);

  @include media("laptop") {
    padding: 20px 20px;
  }
  @include media("mobile") {
    padding: 18px 12px;
  }
}
