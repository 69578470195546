@import "../mixins/media";
@import "../config/sizes";
@import "../config/colors";

.commontable {
  border-collapse: collapse;
  font-size: 15px;
  width: 100%;
  min-width: 850px;
  &-wrapper {
    overflow: auto;
  }
  tr {
    th,
    td {
      &:last-child {
        width: 1%;
      }
    }
    th {
      padding: 4px 8px;
      text-align: left;
      border-bottom: 2px solid color(grey, 500);
    }
    td {
      padding: 14px 8px;
      border-bottom: 2px solid color(grey, 700);
    }
    &:nth-child(2n + 1) {
      td {
        background-color: white;
      }
    }
    &:last-child {
      td {
        border: none;
      }
    }
  }
  &-link {
    color: color(text);
    &:hover {
      color: color(grey, 200);
    }
  }
}
