.banner {
  display: flex;
  gap: 24px;
  margin-top: 40px;
  margin-left: 40px;
  color: white;
  background-color: color(green, 600);
  &-media {
    flex: 0 0 calc((100% + 40px) / 2 - 12px);
    margin-top: -40px;
    margin-left: -40px;
    margin-bottom: 40px;
    img {
      width: 100%;
    }
  }
  &-content {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 40px 48px 40px 24px;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    p {
      margin-bottom: 28px;
    }
    p:last-child {
      margin-bottom: 0;
    }
    &-footer {
      margin-top: auto;
      padding-top: 50px;
    }
  }
  &-title {
    display: block;
    margin-bottom: 16px;
    @extend .title;
  }

  &--light {
    background-color: color(green, 700);
  }
  &--white {
    background-color: white;
    color: color(text);
  }
  &--inverse {
    margin-left: 0;
    margin-right: 40px;
    flex-direction: row-reverse;
    .banner-content {
      padding: 40px 24px 40px 48px;
    }
    .banner-media {
      margin-left: 0;
      margin-right: -40px;
    }
  }

  @include media("laptop") {
    &-content {
      padding: 32px 20px 32px 0px;
      &-footer {
        padding-top: 30px;
      }
    }
    &--inverse {
      .banner-content {
        padding: 32px 0 32px 20px;
      }
    }
  }
  @include media("tablet") {
    display: block;
    padding-top: 20px;
    margin-top: 0px;
    margin-left: 0px;
    &-media {
      margin: 0 20px 20px 20px;
      img {
        max-height: 275px;
        width: auto;
      }
    }
    &-content {
      padding: 0 20px 20px 20px;
      p {
        margin-bottom: 20px;
      }
      p:last-child {
        margin-bottom: 0;
      }
      &-footer {
        padding-top: 30px;
      }
    }
    &--inverse {
      margin-right: 0px;
      .banner-media {
        margin: 0 20px 20px 20px;
      }
      .banner-content {
        padding: 0 20px 20px 20px;
      }
    }
  }
  @include media("mobile") {
    padding-top: 0px;
    &-media {
      margin: 0 0 18px 0;
      img {
        max-height: none;
        width: 100%;
      }
    }
    &-content {
      padding: 0 12px 18px 12px;
      font-size: 18px;
      p {
        margin-bottom: 20px;
      }
      > *:last-child {
        margin-bottom: 0;
      }
    }
    &--inverse {
      .banner-media {
        margin: 0 0 18px 0;
      }
      .banner-content {
        padding: 0 12px 18px 12px;
      }
    }
  }
}
