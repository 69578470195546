@import "../config/colors";
@import "../mixins/hovereffect";

.button {
  display: inline-block;
  padding: 16px 19px;
  background-color: color(sand);
  border: 0;
  border-radius: 0;
  transition: background-color 0.2s;
  color: white;
  text-decoration: none;
  font-family: $fontPoppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  &:hover {
    color: white;
    background-color: color(sand, 300);
  }
  &:focus {
    outline: 2px color(blue) solid;
    outline-offset: 0;
  }
  &:active {
    color: white;
    background-color: color(sand, 400);
  }
  &--disabled {
    color: color(grey, 300);
    background-color: color(grey, 700);
  }
  &--small {
    padding: 5px 10px;
  }
  &--wide {
    min-width: 150px;
  }
  &--responsive {
    @include media("mobile") {
      width: 100%;
      text-align: center;
    }
  }
  &--danger {
    background-color: color(red, 600);
    &:hover {
      color: white;
      background-color: color(red, 500);
    }
    &:focus {
      outline: 2px color(blue) solid;
      outline-offset: 0;
    }
    &:active {
      color: white;
      background-color: color(red, 500);
    }
  }
}
