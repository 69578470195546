.hero {
  position: relative;
  &-image {
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center;
    background-size: cover;
    height: 500px;
  }
  &-box {
    margin-bottom: 0;
    padding: 48px 102px;
    background-color: color(green, 700);
    color: white;
    &-container {
      position: absolute;
      width: 100%;
      bottom: -52px;
      max-height: 100%;
      overflow: hidden;
    }

    &--small {
      padding: 40px 64px;
    }
  }
  &-title {
    @extend .title;
    @extend .title--pagetitle;
  }
  &-subtitle {
    @extend .title;
    @extend .title--sectiontitle;
  }
  &--small {
    .hero-image {
      height: 400px;
    }
  }
  &--hasbox {
    margin-bottom: 52px;
  }
  @include media("laptop") {
    &-image {
      height: 420px;
    }
    &-box {
      padding: 30px 50px;
      &--small {
        padding: 30px 50px;
      }
    }
    &-subtitle {
      font-size: 24px;
      line-height: 34px;
    }
    &--small {
      .hero-image {
        height: 350px;
      }
    }
  }
  @include media("tablet") {
    &-box {
      box-sizing: border-box;
      width: 100%;
      padding: 25px 30px;
      &--small {
        padding: 25px 30px;
      }
    }
    &--small {
      .hero-image {
        height: 330px;
      }
    }
  }
  @include media("mobile") {
    &-image {
      height: 280px;
    }
    &-box-container {
      position: static;
      > .grid-outer-container {
        padding: 0;
      }
    }
    &-box {
      padding: 20px 24px;
      &--small {
        padding: 20px 24px;
      }
    }
    &--hasbox {
      margin-bottom: 0;
    }
    &--small {
      .hero-image {
        height: 280px;
      }
    }
  }
}
