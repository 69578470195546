@import "../mixins/media";
@import "../config/colors";
@import "../config/sizes";

.mobilemenu {
  display: none;
  overflow: auto;
  z-index: 900;

  @include media("tablet") {
    position: fixed;
    display: block;
    top: $headerHeight;
    right: -100%;
    width: 100%;
    height: calc(100% - #{$headerHeight});
    background-color: white;
    transition: right 0.3s;
    &--active {
      right: 0;
    }
  }
  &-content {
    border-top: 1px solid color(grey, 700);
    padding: 0;
  }
  &-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    &-item {
      position: relative;
      font-size: 18px;
      display: block;
      border-bottom: 1px solid color(grey, 900);
      a {
        padding: 16px 60px 15px 20px;
        display: block;
        color: color(text);
      }
      .mobilemenu-list {
        margin: 0px 0 15px 0;
        display: none;
        &-item {
          font-size: 16px;
          line-height: 28px;
          border: none;
          a {
            padding: 11px 60px 9px 35px;
          }
          &:last-child {
            border-bottom: 0;
          }
          .mobilemenu-list {
            margin: 10px 0;
          }
          .mobilemenu-list-item {
            border: none;
            a {
              padding: 11px 10px 9px 50px;
            }
          }
          .mobilemenu-list-item-icon {
            top: 0px;
          }
        }
      }
      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 5px;
        right: 10px;
        width: 48px;
        height: 48px;
        background-color: color(grey, 900);
        box-shadow: inset 0 0 0 7px white;
        transition: background-color 0.2s;
        cursor: pointer;
        i {
          display: none;
        }
        &:hover {
          background-color: color(grey, 700);
        }
      }
      &--expandable {
        > .mobilemenu-list-item-icon i:first-child {
          display: block;
        }
        &.mobilemenu-list-item--expanded {
          > .mobilemenu-list-item-icon {
            i:first-child {
              display: none;
            }
            i:last-child {
              display: block;
            }
          }
          > .mobilemenu-list {
            display: block;
          }
        }
      }
    }
  }
}
