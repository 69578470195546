@import '../config/breakpoints';

// todo: the code below should probably (?) be in a library

@function get-media($identifier) {
  @if map-has-key($breakpoints, $identifier) {
    @return map-get($breakpoints, $identifier);
  } @else {
    @error "Breakpoints does not contain the key `#{identifier}`";
  }
}

@mixin media($identifier) {
  @media #{get-media($identifier)} {
    @content;
  }
}
