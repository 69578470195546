@import "../config/sizes";
@import "../config/colors";
@import "../mixins/vertical-center-child";
@import "../mixins/media";
@import "../mixins/hovereffect";

.header {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 0 40px;
  height: $headerHeight;
  background-color: white;
  color: color(text);
  box-shadow: 0px 4.8px 5.2px 0px rgba(black, 0.02), 0px 24px 32px 0px rgba(black, 0.04);
  &-logo {
    margin: 10px 0;
    @include hovereffect();
  }

  &-menu {
    display: flex;
    gap: 32px;
    font-size: 18px;
    line-height: 24px;
    height: 100%;
    &-item {
      display: flex;
      align-items: center;
      transition: box-shadow 0.2s, color 0.2s;
      color: color(text);
      white-space: nowrap;
      &:hover {
        color: color(green);
      }
      &--active {
        color: color(green);
        box-shadow: inset 0 -4px 0 color(green);
      }
    }
  }

  &-burger {
    display: none;
    padding: 10px;
    font-size: 26px;
    color: color(grey, 300);

    i {
      &:first-child {
        display: block;
      }
      &:last-child {
        display: none;
        margin-right: 3px;
      }
    }
    &--active {
      i {
        &:first-child {
          display: none;
        }
        &:last-child {
          display: block;
        }
      }
    }
  }

  @include media("desktop") {
    padding: 0 30px;
    &-menu {
      gap: 20px;
      &-item {
        font-size: 16px;
      }
    }
  }
  @include media("laptop") {
    padding: 0 20px;
    &-menu {
      gap: 16px;
      &-item {
        font-size: 15px;
      }
    }
  }
  @include media("tablet") {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    height: $headerHeightMobile;

    &-burger {
      display: inline-block;
    }
    &-menu {
      display: none;
    }
    &-logo {
      width: 180px;
    }
  }
  @include media("mobile") {
    padding: 0 12px 0 20px;
    &-burger {
    }
    &-logo {
      width: 143px;
    }
  }
}
