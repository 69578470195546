@import "../config/grid";
@import "../config/breakpoints";
@import "../mixins/media";
@import "~@webnuts/kickstart-grid/styles/grid.scss";

@include grid-outer-container(
  (
    max-width: $gridMaxWidth,
  )
);

@include grid(
  (
    columns: $gridColumns,
    gutter: $gridGutter,
    breakpoints: (
      desktop: (
        gutter: $gridDesktopGutter,
        media: get-media("desktop"),
      ),
      laptop: (
        gutter: $gridTabletGutter,
        media: get-media("tablet"),
      ),
      tablet: (
        gutter: $gridTabletGutter,
        media: get-media("tablet"),
      ),
      mobile: (
        gutter: $gridMobileGutter,
        media: get-media("mobile"),
      ),
    ),
  )
);

// @include grid(
//   (
//     prefix: 'grid10-',
//     columns: 10,
//     gutter: 30px
//   )
// );

.grid-outer-container {
  padding: 0 40px;
  max-width: $gridMaxWidth;

  @include media("desktop") {
    padding: 0 32px;
  }
  @include media("laptop") {
    padding: 0 20px;
  }

  @include media("mobile") {
    padding: 0 12px;
  }
}
