.rte {
  h2 {
    @extend .title;
    margin-bottom: 16px;
  }
  h3 {
    @extend .title;
    @extend .title--sectiontitle;
  }
  h4 {
    @extend .title;
    @extend .title--paragraphtitle;
    margin-bottom: 16px;
  }
  h5 {
    @extend .title;
    @extend .title--contentitle;
  }
  p {
    margin-bottom: 32px;
  }
  a {
    text-decoration: underline;
    color: color(link);
    @include hovereffect();
    &:hover {
      color: color(hover);
    }
  }
  ul {
    margin-bottom: 36px;
    padding-left: 25px;
    li {
      margin-bottom: 16px;
    }
  }
  img {
    height: auto;
    margin-bottom: 5px;
  }
  .text-small {
    display: inline;
  }
  > a:last-child,
  > p:last-child {
    margin-bottom: 0;
  }
}
