.contentbox {
  padding: 64px 24px;
  background-color: white;
  text-align: center;
  box-shadow: 0px 40px 80px rgba(black, 0.04), 0px 8px 13px rgba(black, 0.02);

  @include media("tablet") {
    padding: 45px 24px;
  }
  @include media("mobile") {
    padding: 30px 12px;
  }
}
