.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 16px 0;
  list-style-type: none;
  &-item {
    font-family: $fontPoppins;
    font-size: 13px;
    line-height: 22px;
    color: color(text);
    text-decoration: none;
    @include hovereffect();
    &:hover {
      color: color(text);
    }
    &--current {
      pointer-events: none;
    }
  }
  &-seperator {
    font-size: 8px;
    margin: 0 9px;
    color: color(text);
  }
  &-container {
    position: absolute;
    top: 0;
    width: 100%;
  }
  &--light {
    .breadcrumbs-item {
      color: white;
    }
    .breadcrumbs-seperator {
      color: white;
    }
  }
}
