// h2
.title {
  font-family: $fontOxygen;
  font-weight: bold;
  font-size: 38px;
  line-height: 48px;

  &--pagetitle {
    margin-bottom: 16px;
    font-size: 50px;
    line-height: 63px;
  }

  // h3
  &--sectiontitle {
    margin-bottom: 16px;
    font-size: 28px;
    line-height: 38px;
  }

  // h5
  &--contentitle {
    font-size: 20px;
    line-height: 28px;
  }

  // h4
  &--paragraphtitle {
    font-size: 24px;
    line-height: 34px;
  }

  @include media("mobile") {
    font-size: 32px;
    line-height: 38px;
    &--pagetitle {
      font-size: 50px;
      line-height: 63px;
    }
    &--sectiontitle {
      font-size: 28px;
      line-height: 38px;
    }
    &--contentitle {
      font-size: 20px;
      line-height: 28px;
    }
    &--paragraphtitle {
      font-size: 24px;
      line-height: 34px;
    }
  }
}
