.spacer {
  height: 64px;

  &--tiny {
    height: 16px;
  }
  &--small {
    height: 24px;
  }
  &--medium {
    height: 40px;
  }
  &--large {
    height: 96px;
  }
  &--huge {
    height: 160px;
  }

  @include media("tablet") {
    &--large {
      height: 70px;
    }
    &--huge {
      height: 130px;
    }
  }
  @include media("mobile") {
    height: 50px;

    &--tiny {
      height: 16px;
    }
    &--small {
      height: 24px;
    }
    &--medium {
      height: 40px;
    }
    &--large {
      height: 70px;
    }
    &--huge {
      height: 120px;
    }
  }
}
