@import "../mixins/media";
@import "../config/sizes";
@import "../config/colors";

.schema-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  > *:last-child {
    margin-left: auto;
  }
  a {
    text-decoration: underline;
    color: color(link);
    @include hovereffect();
    &:hover {
      color: color(hover);
    }
  }
}

.schema-steps {
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;
  row-gap: 15px;
}

.schema-steps-item {
  position: relative;
  flex: 0 0 25%;
  text-align: center;
  overflow: hidden;
  font-size: 16px;
}

.schema-steps-item:before {
  position: absolute;
  z-index: 50;
  content: "";
  border-top: 1px solid #a4a4a4;
  left: 0;
  right: 0;
  top: 15px;
}
.schema-steps-item:nth-child(4n + 1):before {
  left: 50% !important;
}
.schema-steps-item:last-child:before {
  right: 50% !important;
  left: 0;
}
.schema-steps-item:nth-child(4n):before {
  right: 50%;
  left: 0;
}
.schema-steps-item .number {
  display: block;
  color: #fff;
  position: relative;
  z-index: 90;
  width: 34px;
  height: 34px;
  line-height: 22px;
  border-radius: 99px;
  background-color: #a4a4a4;
  border: 6px solid #f5f5f5;
  margin: 0 auto;
  font-size: 14px;
}
.schema-steps-item {
  .text {
    color: #a4a4a4;
  }
}
.schema-steps-item.schema-steps-item--active .text {
  color: #0d682f;
}
.schema-steps-item--active .number {
  background-color: #0d682f;
}

.schema-step-title {
  padding: 14px 40px;
  background-color: #00775a;
  display: block;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  font-weight: 700;
}

.schema-step-content {
  margin-bottom: 40px;
  padding: 30px 40px;
  background: #fff;
  border-style: solid;
  border-color: #e8e8e8;
  border-width: 0 1px 1px;
}

.schema-question {
  display: flex;
  gap: 40px;
  margin-bottom: 40px;
  label.error {
    display: inline-block;
    margin-top: 2px;
    color: color(red, 600);
  }
}

.schema-tag {
  color: color(red, 600);
}

.schema-question-left {
  flex: 0 1 30%;
  padding-top: 7px;
}

.schema-question-right {
  flex: 0 1 70%;
}
.schema-question-wide {
  flex: 0 1 100%;
}

.schema-question-help {
  font-size: 0.8em;
  font-style: italic;
  color: color(grey, 300);
}

.schema-step {
  display: none;
}

.schema-step--active {
  display: block;
}

.schema-question input[type="email"],
.schema-question input[type="text"],
.schema-question input[type="number"],
.schema-question input[type="date"],
.schema-question textarea {
  padding: 10px 13px 9px 13px;
  font-size: 16px;
  line-height: 20px;
  box-shadow: none !important;
  width: 100%;
  border: 1px solid #a4a4a4;
  &.error {
    border-color: color(red, 600);
  }
}
.schema-question textarea {
  display: block;
  height: 225px;
  padding: 13px 13px;
}
.schema-question select {
  width: 100%;
  height: 41px;
  padding: 0 8px 0 8px;
  border: 1px solid #a4a4a4;
}

.schema-question .schema-checkbox-wrapper {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
  align-items: flex-start;
  line-height: 22px;
  &:first-child {
    margin-top: 10px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  input[type="checkbox"] {
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    border-radius: 0;
  }
  label.error {
    margin-top: 0;
  }
}
.schema-question .schema-radio-wrapper {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
  align-items: flex-start;
  line-height: 22px;
  &:first-child {
    margin-top: 10px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  input[type="radio"] {
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    border-radius: 0;
  }
}

.schema-question {
  input[type="file"] {
    font-size: 16px;
  }
  input::-webkit-file-upload-button {
    display: inline-block;
    padding: 7px 10px 6px 10px;
    margin-top: 0px;
    margin-right: 12px;
    vertical-align: baseline;
    border: 0;
    color: white;
    background-color: color(sand);
    text-decoration: none;
    font-family: $fontPoppins;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
}

.schema-dialog {
  padding: 13px;
  background: white;
  border: 1px solid #a4a4a4;
}

@include media("tablet") {
  .schema-question {
    gap: 5px;
    margin-bottom: 30px;
  }
  .schema-step-content {
    padding: 25px 25px;
  }
  .schema-step-title {
    padding: 15px 25px;
  }
  .schema-question {
    flex-wrap: wrap;
  }
  .schema-question-left {
    padding-top: 0;
    flex: 0 0 100%;
  }
  .schema-steps-item .text {
    display: none;
  }
  .schema-question-right {
    flex: 0 10 100%;
  }
  .schema-controls {
    font-size: 16px;
  }
}
@include media("mobile") {
  .schema-step-content {
    padding: 22px 20px;
  }
  .schema-step-title {
    padding: 15px 20px;
  }
  .schema-controls {
    flex-direction: column;
    gap: 10px;
    > *:first-child {
      order: 2;
    }
    > *:last-child {
      order: 1;
    }
  }
}
