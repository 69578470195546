.gallery {
  display: flex;
  flex-wrap: wrap;
  &-item {
    display: block;
    flex: 0 1 120px;
    margin: 0 5px 5px 0;
    @include hovereffect();
    img {
      width: 100%;
    }
  }
}
