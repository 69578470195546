.asidemenu {
  margin: 0 0 0 6px;
  padding: 0;
  list-style-type: none;
  &-item {
    position: relative;
    margin-bottom: 12px;
    padding-left: 20px;
    font-size: 16px;
    font-weight: normal;
    i {
      position: absolute;
      top: 4px;
      left: 0;
    }
    a {
      color: color(grey, 100);
      @include hovereffect();
    }
    .asidemenu {
      display: none;
      margin: 12px 0 0 0;
    }
    &--expands {
      > i {
        font-size: 18px;
        top: 3px;
      }
    }
    &--expanded {
      margin-bottom: 24px;
      > i {
        font-size: 19px;
        left: -2px;
        top: 3px;
      }
      > .asidemenu {
        display: block;
      }
    }
    &--active {
      > a {
        color: color(green, 600);
        font-weight: bold;
      }
      > i {
        color: color(green, 600);
      }
    }
  }
}
