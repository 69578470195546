.accordion {
  display: none;
  &.ui-accordion {
    .ui-accordion-header {
      margin-bottom: 8px;
      padding: 14px 45px 14px 16px;
      font-size: 18px;
      line-height: 28px;
      background-repeat: no-repeat;
      background-position: calc(100% - 20px) center;
      background: color(grey, 700) url(/img/plus.svg) no-repeat calc(100% - 20px) center;
      transition: margin 0.35s, background-color 0.35s;
      cursor: pointer;
      &.ui-accordion-header-active {
        background-image: url(/img/minus.svg);
        background-color: color(grey, 500);
      }
    }
    .ui-accordion-content {
      padding: 16px 16px 56px 16px;
      font-size: 16px;
      line-height: 24px;
      > *:last-child {
        margin-bottom: 0;
      }
      &:last-child {
        padding-bottom: 16px;
      }
    }
    @include media("mobile") {
      .ui-accordion-content {
        padding: 10px 0 40px 0;
      }
    }
  }
}
